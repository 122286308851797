import React, { useState, useEffect } from "react";
import './App.css';
import './css/About.css';
import './css/Modal.css';
import './css/Services.css';
import { useAlert } from 'react-alert';

export default function App() {
  return (
    <div>
    <nav><img src='imgs/logo.png' style={{height: '3em'}}/><h2>Maintenance</h2></nav>
      
          <Home/>       
        
       
      <footer>
        <div>
        <li><a href='tel:+1-617-438-2164'><p>Give us a call</p></a></li>
        <li><a href='mailto:luismonteiro103@yahoo.com'><p>Send us an Email</p></a></li>
        </div>
        
        <li style={{fontSize:'8px'}}>Copyright © 2021 LM Maintenance - All Rights Reserved.</li>
        <li style={{fontSize:'8px'}}>This Website Was created by Miguel Monteiro(https://www.mgmwebdev.com)</li>
        
      </footer>
    </div>

  );
}

//About
function Home() {
  const [modal, setModal] = useState(false);
  const [show, setShow] = useState(false);
 const openModal = () => setShow(true);
  const closeModal = () => setShow(false);
  componentDidUpdate:
  useEffect(() => {
    
  },[]);
  return (
    
    <div className='content'>
      <About/>
      <Services openModal={openModal}/>
      <Modal closeModal={closeModal} show={show}/>
    </div>
  );
}
function About() {

  return (
      <div className='about'>
        <div className='headshot'>
        
        <img className='HeadShot'alt='HeadShot/Logo/Picture' src='/imgs/llm-logo.png'/>
        </div>
        <div className="bio">
        <h3>LM Maintenance</h3>
        <p>LM Maintenance LLC was created in February of 2020 by the Owner Luis Monteiro. The team has more 
        then 30 years of experience in Tiling, Landscaping, Plowing, Cement, Construction, and Appliance Repair/Install. LM Maintenanced is a Certified Home Improvement 
        Contractor (HIC) in the state of Massachusetts.</p>
        <p>We are located in Boston, and service all of Eastern Massachusetts.</p>
        </div>
        
      </div>
  );
}
function Services({openModal}) {
  const [mobile, setMobile] = useState(true);

  return (
    <div className='services'>
      
        <li>
          <div className="imgContainer">
        <img src='/imgs/plow.jpg'/>
        </div>
        <h3>Services</h3>
          <div className="servicesContent">
        <ul>
            <li>         
              Landscaping
            </li>
            <li>
            
              
              Plowing
              
              </li>
              <li>
            
            Trash Removal


          </li>
            <li>
          
              
              Tile
              </li>
            <li>Small Demolition </li>
            <li>
            
              Basement Cleaning


            </li>
            <li>        
          Appliance Removal
        </li>
          <li> Property Maintenance</li>
          <li> Raw Material Delivery (Supply Center to Home)</li>
        </ul>
        </div>
        </li>

        <li>
        <div className="imgContainer">
        <img src='/imgs/landscaping2.jpg'/>
        </div>
        <h3>Work Experience</h3>
        <div className="servicesContent">
        <p>
        The team has more then 30 years of experience in Tiling, Landscaping, Plowing, Cement,
        Construction, and Appliance Removal/Install. 
        </p>
        </div>
          </li>
        <li>
        <div className="imgContainer">

        <img src='/imgs/trash.jpg'/>
        </div>
          <h3>
          Schedule a Quote
          </h3>
          <div className="servicesContent">
          <p>If you are interested in our services and want to schedule a quote. Please fill out the the Request form. We will respond your request via email, phone call or text.</p>
          <span className='quoteReq' onClick={()=>openModal()}>Request a quote</span>
          </div>
          </li>
        {mobile? <li className="contact">
        <div className="imgContainer">
          
          <img src='/imgs/truck2.jpg'/>
          </div>
        <h3>Contact</h3>
        <div className="servicesContent">
          <p>Email : <a href='mailto:luismonteiro103@yahoo.com'>luismonteiro103@yahoo.com</a></p>
          <p>Phone : <a href='tel:+1-617-438-2164'>+1-617-438-2164</a></p>
          <a className="socialContainer" href="https://www.facebook.com/LM-Maintenance-104545764714666" target='_blank'><img src={'/imgs/facebook.png'} style={{width:'25px',height:'25px'}}/></a>
        </div>
        </li>:null}
        
       </div>
  )}
function Modal(props){
  const { show, closeModal } = props;
  const [dets, moreDets] = useState(false);
  const [name, setName] = useState('');
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [valid, setValid] = useState(false);
  const [validPhone, setPhoneValid] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phone, setPhone] = useState('');
  const [description, setDesc] = useState('');
  const [pref,setPref] = useState({Monday:false,Tuesday:false,Wednesday:false,Thursday:false,Friday:false});
  const validateEmail = (email) => 
    {
      console.log('Ran')
      var email_pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (email_pattern.test(email)) {
        console.log('valid email')
        setValid(true)

        setError(false)
          
      } else {
        console.log('invalid email')
        setValid(false)

        setError(true)
      }
      
  }

  const validatePhone = (phone) => 
  {
    console.log('Ran')
    var phone_pattern = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
    if (phone_pattern.test(phone)) {
      console.log('valid phone')
      setError(false)
      setPhoneValid(true)
    } else {
      console.log('invalid phone')
     
            setError(true)
            setPhoneValid(false)
    }
}
const clearData = () =>{
  console.log(pref)
  setPref({Monday:false,Tuesday:false,Wednesday:false,Thursday:false,Friday:false});
  
}
const alert = useAlert();
const quote = () =>{
  var url='https://api.mgmwebdev.com/lmmaintenance';


  fetch(url, {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, ',
      'Content-Type': 'application/json'
    },
    body : JSON.stringify({name:name,email:email,phone:phone,description:description})
  }).then(res=>res.json()).then(result=>{
      if(result.sent){
        clearData()
        alert.show('Thank you for your request. You should be hearing from us in the next 24hrs.')
        
      }
      else{
        alert.show('There was an issue. Please feel free to call or email. ')
      }
    }).catch(e=>console.log(e.stack))
    
    
}
  return (
    <>
    <div className={show ? "overlay" : "hide"} onClick={()=>closeModal()} />
      <div className={show ? "modal" : "hide"}>
        <h1>Request A Quote  <button className='closeModal'style={{justifiySelf:'flex-end',alignSelf:'flex-start'}} onClick={()=>{closeModal()}}>X</button></h1>
        <li><label>Name</label> <input type='text' value = {name} onChange={e=>{setName(e.target.value)}}/>{name.length > 3 ? <img src='/imgs/check.png'/>:null}</li>
        <li><label>Email</label> <input type='email' value = {email} onChange={e=>{ setEmail(e.target.value)}} onBlur={e=>validateEmail(e.target.value)}/>{valid ? <img src='/imgs/check.png'/>:null}</li>
        <li><label>Phone</label> <input type='tel' value = {phone} onChange={e=>{setPhone(e.target.value)}} onBlur={e=>validatePhone(e.target.value)}/>{validPhone  ? <img src='/imgs/check.png'/>:null}</li>
        <li><label style={{lineHight:4}}>Description</label> <textarea value = {description} onChange={e=>{setDesc(e.target.value)}}/></li>
       {/*<li><label>Availability</label> <span style={{fontSize:'.7em',flexWrap:'wrap',justifySelf:'stretch',flexDirection:'row'}}>
        <li><input name='Monday'type='checkbox' checked={!!pref.Monday} onChange={()=>{ pref.Monday=!!pref.Monday;setPref(pref)}}/><label name='Monday'>Monday</label> </li>
        <li><input name='Tuesday'type='checkbox' checked={pref.Tuesday} onChange={e=>{ pref.Tuesday=!pref.Tuesday;setPref(pref)}}/><label name='Tuesday'> Tuesday </label></li>
        <li><input name='Wednesday'type='checkbox' checked={pref.Wednesday} onChange={e=>{ pref.Wednesday=!pref.Wednesday;setPref(pref)}}/><label name='Wednesday'>Wednesday</label></li>
        <li><input name='Thursday'type='checkbox' checked={pref.Thursday} onChange={e=>{ pref.Thursday=!pref.Thursday;setPref(pref)}}/><label name='Thursday'> Thursday </label> </li>
        <li><input name='Friday'type='checkbox' checked={pref.Friday} onChange={e=>{ pref.Friday=!pref.Friday;setPref(pref)}}/> <label name='Friday'>Friday</label></li>
        
  </span></li>*/ }
        
        <div onClick={()=>{moreDets(!dets)}} style={{display:'flex',cursor:'pointer',fontSize:'1.2em'}}>More Details On Rates {dets? <span>	&#9650;</span>:<span>&#9660;</span>}</div>
        { dets?
        <div>
        <div className='rates'>
          <ul>
            <h5 >Construction Demolition Removal</h5>
              <p >
                Trash Removal $370.00/ton
              </p>
              <p >
              $100 if aditional labor is required
              </p>

          </ul>
          <ul>
            <h5 >Masonry Removal</h5>
            <p >
                Cinder Blocks, Red Bricks, Mixed Dirt, Mixed Concrete at $120.00/ton
                </p>
          </ul>
          <ul>
            <h5> Landscaping Removal</h5>
            <p >
                Leaves,BrushmGrass,Logs,WHole Trees, Stumps, Pallets, Fence Post at $120.00/ton
                </p>
          </ul>
        </div>
        <span style={{fontSize:10}}>***We do Not take, Tires, Asphalt, Mattresses, Paint Cans, Household Trash, Insulation Blocks, Monitors,Computers,TVs, Hazardous or Contaminated Materials.</span>
        
        </div>:null

        }
   {error && !valid ||error && !validPhone? <> {!valid && !validPhone && phone!=='' && email!=='' ? <div className="error">Email and Phone Number are Invalid</div>:!valid &&validPhone ? <div>Email is Invalid</div>: valid && !validPhone ?<div>Phone Number is Invalid</div>:null}</>:null}
   {valid && name.length > 3  && validPhone ? <li className='quoteReq' style={{alignSelf:'stretch',justifyContent:'center'}} onClick={quote}>Submit Request</li>:null}
      </div>
    </>
  );
}




